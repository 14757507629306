class sidebarNav {
  constructor() {
    if (!this.setVars()) return
    this.setEvents()
  }

  setVars() {
    this.sidebar = document.body.getElementsByClassName('sidebar')[0]
    if(!this.sidebar) return false

    this.closeBtn = document.body.querySelector('[data-btn_nav]')
    if(!this.closeBtn) return false

    this.navList = document.body.getElementsByClassName('nav-list')[0]
    return this.navList
  }

  setEvents() {
    this.closeBtn.addEventListener("click", () => {
      this.sidebar.classList.toggle("open");
      this.navList.classList.toggle("scroll");
      this.menuBtnChange();
    })

    document.querySelectorAll('.dropdown-toggle').forEach((el)=>{
      this.dropDownFunc(el)
    })

    // Listen to the doc click
    window.addEventListener('click', (e) => {
      if (e.target.closest('.dropdown-container') === null) this.closeDropdown()
    })

    document.querySelectorAll('.dropdown-menu').forEach((dropDownList) => dropDownList.onmouseleave = this.closeDropdown)
  }

  dropDownFunc(dropDown) {
    if (dropDown.classList.contains('click-dropdown') === true) {
      dropDown.addEventListener('click', (e) => {
        e.preventDefault();
        if (dropDown.nextElementSibling.classList.contains('dropdown-active') === true) {
          dropDown.parentElement.classList.remove('dropdown-open');
          dropDown.nextElementSibling.classList.remove('dropdown-active');

        } else {
          this.closeDropdown()
          dropDown.parentElement.classList.add('dropdown-open')
          dropDown.nextElementSibling.classList.add('dropdown-active')
        }
      })
    }

    if (dropDown.classList.contains('hover-dropdown') === true) {
      dropDown.onmouseover = dropDown.onmouseout = this.dropdownHover;
    }
  }

  dropdownHover(e) {
    if (e.type === 'mouseover') {
      this.closeDropdown()
      e.target.parentElement.classList.add('dropdown-open')
      e.target.nextElementSibling.classList.add('dropdown-active')
    }
  }

  closeDropdown() {
    document.querySelectorAll('.dropdown-container').forEach((container) => container.classList.remove('dropdown-open'))
    document.querySelectorAll('.dropdown-menu').forEach((menu) => menu.classList.remove('dropdown-active'))
  }

  menuBtnChange() {
    if (this.sidebar.classList.contains("open")) {
      this.closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
    } else {
      this.closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
    }
  }
}

export default sidebarNav

class init_tinymce {
  constructor(el) {
    if (!this.setVars(el)) return;
    this.init(this.el);
  }

  setVars(el) {
    this.els = document.body.querySelectorAll(el)
    return this.els.length
  }

  init() {
    addJS('https://cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.11/tinymce.min.js', {
      id: 'mce0', cb: () => {
        each(this.els, (key, val) => {
          tinymce.init({
            selector: `#${val.id}`,
            skin: "lightgray",
            inline: false,
            custom_colors: true,
            plugins: [
              "textcolor colorpicker link image imagetools table paste"
            ],
            paste_as_text: true,
            toolbar: "paste undo redo styleselect bold link image italic alignleft aligncenter alignright alignjustify bullist numlist outdent indent forecolor backcolor",
          })
        })
      }
    })
  }
}

export default init_tinymce

import {_request, modal} from "../functions";

class api {
  constructor() {
    if (!this.setVars()) return false
    this.recaptcha = document.body.querySelector('[data-callback="submit"]')
    let recaptcha_public_key = '6LcxElsqAAAAAD-dE92EIfe94ZYMdy9H9KHHsy1z'
    if (this.recaptcha) {
      this.recaptcha.disabled = true
      addJS(`https://www.google.com/recaptcha/api.js?render=${recaptcha_public_key}`, {
        id: '_captcha', async: true, defer: true, cb: () => {
          this.is_valid = false
          grecaptcha.ready(() => {
            grecaptcha.execute(`${recaptcha_public_key}`, {action: 'submit'}).then((response) => {
              _request('api/recaptcha', JSON.stringify({
                _token: csrf,
                response: response
              }), {
                cb: (xhr) => {
                  xhr.json().then(json => {
                    switch (json.success) {
                      case true:
                        this.is_valid = true
                        break;
                    }
                  })
                  this.setEvents()
                }
              })

            })
          })
        }
      })
    } else this.setEvents()
  }

  setVars() {
    this.forms = document.body.querySelectorAll('form[data-api]')
    return this.forms.length
  }

  setEvents() {
    let data = {}, autoSubmit;
    if (this.recaptcha) this.recaptcha.disabled = false

    each(this.forms, (key, val) => {
      let upl = document.body.querySelector('[data-upload]')
      upl && upl.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        upl.previousElementSibling.click()
      })

      let file = val.querySelector('input[type="file"]')
      file && file.addEventListener('change', (e) => {
        let image = e.target.previousElementSibling
        image.src = URL.createObjectURL(e.target.files[0]);
        image.onload = ()=> URL.revokeObjectURL(image.src)
      })


      val.addEventListener('submit', (e) => {
        val.dataset.auto !== '1' && e.preventDefault()
        if (!this.is_valid && this.recaptcha) return this.captchaError()
        if (!val.length) return
        if (!val.dataset.api) return
        this.formData = new FormData()

        if (this.validateForm(val)) return modal({'status': false, 'msg': 'Uzupełnij wymagane pola'})

        let coordinates = val.querySelector('[name="coordinates"]')
        console.log(coordinates)
        if (coordinates && !this.validateAddress(coordinates)) return modal({
          'status': false,
          'msg': 'Błędny adres szkoły.<br>Sprawdź czy adres interaktywnej mapy jest identyczny z uzupełnionymi danymi formularza'
        })

        if (!this.orderData._token) this.orderData._token = csrf
        if (!this.is_valid && this.recaptcha) return this.captchaError()

        if (val.dataset.auto === '1') {
          val.method = 'post'
          val.action = val.dataset.api
          return val.submit()
        }

        if (val.dataset.type === 'multipart') {
          each(this.orderData, (key, value) => {
            this.formData.append(key, value)
          })
        }

        _request(val.dataset.api, val.dataset.type === 'multipart' ? this.formData : JSON.stringify(this.orderData), {
          type: val.dataset.type || '',
          cb: (xhr) => {
            xhr.json().then(response => {
              val.reset()
              let btn_delete = val.querySelector('[data-delete]')
              btn_delete && del(document.getElementById(btn_delete.dataset.delete))
              response.msg ? modal(response, () => {
                this.redirect(response)
              }) : this.redirect(response)
            })
          }
        })
      })

      autoSubmit = val.querySelectorAll('[data-submit="auto"]')
      autoSubmit.length && each(autoSubmit, (i, field) => {
        field.addEventListener('change', () => {
          try {
            val[val.length - 1].click()
          } catch (e) {
            console.error('invalid submit button')
          }
        })
      })
    })
  }


  validateAddress(coordinates) {
    if (!coordinates.dataset.street || !coordinates.dataset.postal || !coordinates.dataset.city) return false
    let address1 = `${coordinates.dataset.street.toLowerCase()} ${coordinates.dataset.postal.toLowerCase()} ${coordinates.dataset.city.toLowerCase()}`,
      address2 = `${this.orderData.street.toLowerCase()} ${this.orderData.house_nr.toLowerCase()} ${this.orderData.postal.toLowerCase()} ${this.orderData.city.toLowerCase()}`
    return address1 === address2
  }

  resetReCaptcha(valid = false) {
    this.is_valid = valid;
    grecaptcha && grecaptcha.reset()
  }

  captchaError(callback) {
    return modal({msg: 'Nie udało się zweryfikować reCaptcha,<br>Spróbuj ponownie.'}, {
      cb: () => {
        if (typeof callback === 'function') callback()
      }
    })
  }

  redirect(response) {
    if (response.reload) return window.location.reload()
    if (!response.url) return;
    window.location.href = response.url
  }

  validateForm(form) {
    this.orderData = {
      checkboxesName: [],
      checkboxesVal: []
    }
    let inputs = form.querySelectorAll('input,select,textarea'), minLength = 0, error = []
    if (!inputs.length) return;

    each(inputs, (key, val) => {
      minLength = parseInt(val.dataset.min) || 0
      switch (val.type) {
        case 'checkbox':
          if (val.dataset.req === '1' && val.checked) {
            val.parentNode.classList.remove('error')
          } else if (val.dataset.req === '1' && !val.checked) {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
          //this.orderData.checkboxes[`${val.name}`] = val.checked
          this.orderData.checkboxesName.push(val.name)
          this.orderData.checkboxesVal.push(val.checked)
          if (form.dataset.type !== 'multipart') this.orderData[val.name] = val.checked
          break
        case 'email':
          if (is_email(val.value)) {
            val.parentNode.classList.remove('error')
            this.orderData[val.name] = val.value.toLowerCase()
          } else {
            val.parentNode.classList.add('error')
            error.push(val.name)
          }
          break
        case 'file':
          this.formData.append('file', val.files[0])
          break
        default:
          switch (val.name) {
            case 'coordinates':
              if (!val.value.length) {
                form.querySelector('[data-search_input]').classList.add('error')
              } else {
                form.querySelector('[data-search_input]').classList.remove('error')
                let coordinates = val.value.split(',')
                this.orderData['lat'] = coordinates[0]
                this.orderData['lng'] = coordinates[1]
              }
              break
            case 'description':
              each(tinyMCE.editors, (i, el) => {
                this.orderData[`${val.name}`] = tinyMCE.editors[i].getContent({format: 'raw'})
              })
              break
            default:
              if (val.value.length >= minLength) {
                val.parentNode.classList.remove('error')
                this.orderData[val.name] = val.value
              } else {
                val.parentNode.classList.add('error')
                error.push(val.name)
              }
          }
      }
    })
    return error.length
  }
}

export default api

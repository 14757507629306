import '../sass/core.scss'
import '../sass/framework/hd.scss'

import "./global"
import {lazyCSS} from "./class/lazyCSS"
import {lazyImg} from "./class/lazyImg"
import search from "./class/search"
import api from "./class/api";
import sidebarNav from "./class/sidebarNav";
import updateVar from "./class/updateVar";
import showInputPassword from "./class/showInputPassword";
import init_tinymce from "./class/init_tinymce";
import Splide from "@splidejs/splide";

const mod = {};

// ======== load core =========
mod.core = () => {
  const token = document.head.querySelector('meta[name="_token"]')
  window.csrf = token ? token.content : '';
  mod.css = new lazyCSS()
  mod.lazy = new lazyImg('[data-url]')
  mod.tinymce = new init_tinymce('textarea.edit');
  mod.api = new api()
  mod.search = new search()
  mod.showInputPassword = new showInputPassword()
  mod.sidebarNav = new sidebarNav()
  mod.updateVar = new updateVar()
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', mod.core) : mod.core()

// Advanced menu
let moreOption = document.getElementById("moreOption")
if (moreOption) {
  moreOption.addEventListener("click", showAdvencedMenu)

  function showAdvencedMenu() {
    let AdvencedMenu = document.getElementById("advanceSearch")
    let rotate = document.getElementById("arrow")
    AdvencedMenu.classList.toggle("show")
    rotate.classList.toggle("rotate")
  }
}

// Menu
let toggleMenu = document.getElementById("btnToggle")
if (toggleMenu) {
  toggleMenu.addEventListener("click", showMenu)

  function showMenu() {
    let menu = document.getElementById("menu")
    let height = document.getElementById("navbar")
    menu.classList.toggle("show-menu")
    height.classList.toggle("add-height")
  }
}

//Slider gallery
const gallerySlider = document.querySelectorAll('.gallery-slider');

if (gallerySlider) {
  gallerySlider.forEach((slider)=>{
        new Splide( slider, {
            type: 	'loop',
            rewind: true,
            perPage: 1,
            perMove: 1,
            pagination: false,
            arrowPath: 'M2,16.139H32.445L20.583,4.308l1.172-1.169L35.605,16.954,21.754,30.768,20.583,29.6,32.42,17.793H2Z',
            live: false,

            breakpoints: {
                768: {
                    perPage: 1,
                    pagination: true,
                    live: false,
                    arrows: false,
                }
            }
        } ).mount();
    });
}

import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {_request, modal} from "../functions";

class search {
  constructor() {
    if (!this.setVars()) return
    this.setEvents()
  }

  setVars() {
    this.rel = document.body.querySelector('[data-gmap]')
    if (!this.rel) return false

    this.api_url = 'api/map'
    this.locationsArr = []
    this.search_input = document.body.querySelector('[data-search_input]')

    return true
  }

  setEvents() {
    this.rel.dataset.mark === '1' ? this.loadMaps() : this.loadLocations()
  }

  loadMaps() {
    window.initMap = this.initMap.bind(this)
    addJS('https://maps.googleapis.com/maps/api/js?key=AIzaSyAzv_umTBnIFfDO5oFy96YQUmHGaAXSgWI&callback=initMap&language=pl&loading=async&libraries=maps,marker,places&v=beta')
  }

  loadLocations() {
    _request(this.api_url, JSON.stringify({
      _token: csrf,
      filter: 'all'
    }), {
      cb: (xhr) => {
        xhr.json().then(response => {
          this.locationsArr = response;
          this.loadMaps()
        })
      }
    })
  }

  async initMap() {
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        /*{
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        /*{
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }*/
      ],
      {name: 'Styled Map'});

    this.mapOptions = {
      zoom: 10,
      center: {lat: 52.2916808, lng: 21.0840602},
      disableDefaultUI: true,
      gestureHandling: "cooperative",
      zoomControl: true,
      mapId: 'AIzaSyAzv_umTBnIFfDO5oFy96YQUmHGaAXSgWI',
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    }

    const map = new google.maps.Map(this.rel, this.mapOptions)
    map.mapTypes.set('styled_map', styledMapType)
    map.setMapTypeId('styled_map')
    this.markers = [];
    this.icon = this.rel.dataset.marker || 'img/marker.svg'
    if (this.search_input) this.searchAutoComplete(map)
    if (this.rel.dataset.lat && this.rel.dataset.lng) return this.setCustomLocation(map, this.rel.dataset.lat, this.rel.dataset.lng, 8)
    if (this.locationsArr.length) this.setMarkers(map)
  }

  setMarkers(map) {
    //this.hideMarkers()
    let markers = this.locationsArr.map((position, i) => {
      const marker = new google.maps.Marker({
        position: {lat: parseFloat(position.lat), lng: parseFloat(position.lng)},
        icon: this.icon,
        label: {
          text: `${position.price} zł`,
          color: 'white',
          className: 'marker-position'
        }
      })

      const infoWindow = new google.maps.InfoWindow({
        content: '',
        disableAutoPan: true,
      })

      marker.addListener('click', () => {
        infoWindow.setContent(`<strong>${position.name}</strong><br>ul. ${position.street} ${position.house_nr} ${position.flat_nr ? `/ ${position.flat_nr}` : ''}<p>Kursy od <strong>${position.price}</strong> zł</p>`)
        infoWindow.open(map, marker)
      })
      return marker
    })

    const markerCluster = new MarkerClusterer({markers, map})
  }

  searchAutoComplete(map) {
    const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement()
    this.search_input.appendChild(placeAutocomplete)
    placeAutocomplete.addEventListener("gmp-placeselect", async ({place}) => {
      await place.fetchFields({
        fields: ["displayName", "formattedAddress", "location"],
      })
      let data = place.toJSON()
      map.setCenter(new google.maps.LatLng(data.location.lat, data.location.lng), 14)
      this.markLocations(map, data)
    })
  }

  setCustomLocation(map, lat, lng, zoom = 8) {
    this.markLocations(map, {
      location: {
        lat: parseFloat(this.rel.dataset.lat),
        lng: parseFloat(this.rel.dataset.lng)
      }
    })
    map.setCenter(new google.maps.LatLng(parseFloat(this.rel.dataset.lat), parseFloat(this.rel.dataset.lng)), 8)
  }

  setMapOnAll(map) {
    let i = 0
    for (i; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  hideMarkers() {
    this.setMapOnAll(null)
  }

  deleteMarkers() {
    this.hideMarkers()
    this.markers = []
  }

  markLocations(map, data) {
    let coordinates = document.body.querySelector('input[name="coordinates"]')
    if (!coordinates) return
    this.deleteMarkers()
    if (data.displayName) this.fillAddress(data, coordinates)
    coordinates.value = `${data.location.lat},${data.location.lng}`
    const marker = new google.maps.Marker({
      position: {lat: data.location.lat, lng: data.location.lng},
      icon: this.icon,
      label: {
        text: 'OSK',
        color: 'white',
        className: 'marker-position'
      }
    })
    this.markers.push(marker)
    this.setMapOnAll(map)
  }

  fillAddress(data, coordinates) {
    coordinates.dataset.street = data.displayName || ''
    let address = data.formattedAddress.split(',')
    let postal = address[1].slice(1).split(' ')[0] || ''
    let city = address[1].slice(1).split(' ')[1] || '',
      streetName = '', house_nr = ''
    coordinates.dataset.postal = postal
    coordinates.dataset.city = city

    let input_street = document.body.querySelector('input[name="street"]')
    if (input_street) {
      let splitName = data.displayName.split(' ')
      each(splitName, (key, val) => {
        if(key < splitName.length - 1) {
          streetName += ` ${val}`
        } else house_nr = val
      })
      input_street.value = streetName.slice(1)
    }

    let input_house_nr = document.body.querySelector('input[name="house_nr"]')
    if (input_house_nr) input_house_nr.value = house_nr

    let input_postal = document.body.querySelector('input[name="postal"]')
    if (input_postal) input_postal.value = postal

    let input_city = document.body.querySelector('input[name="city"]')
    if (input_city) input_city.value = city
  }

}

export default search

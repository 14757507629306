export function _request(url, data, cfg = {}) {
  let controller = new AbortController(), obj = Object.assign({
    signal: controller.signal,
    method: 'POST',
    mode: 'same-origin',
    credentials: 'same-origin',
    //'Content-Type': 'application/json', //multipart/form-data, application/json, application/x-www-form-urlencoded
    headers: {
      'Accept': 'text/csv, application/json, application/xml, text/plain, text/html, application/pdf, image/jpeg, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'Content-Type': typeof cfg.type !== 'undefined' ? cfg.type : 'application/json'
    },
    body: data,
    cb: null,
  }, cfg);

  obj.type && obj.type.indexOf('multipart') > -1 && delete obj.headers;

  fetch(url, obj).then(response => {
    response.status !== 200 && controller.abort();
    typeof obj.cb === 'function' && obj.cb(response);
  });
}

export function modal(response = '', cb) {
  let modal = render(document.body, `<div id="modal" class="row h100 fix top0 left0 flex align-center justify-center" style="z-index:900;background-color:rgba(0,0,  0,.5)">
  <div class="modal shad ${!response.status ? 'alert' : ''}" style="max-width:470px;width:100%;padding:2em;border-radius:15px;background-color:#fff">
    <h6 style="font-size:1em;font-weight:300">${response.msg}</h6>
    <button class="btn btn--sm mt30">OK</button>
  </div>
</div>`)
  let btn = modal.getElementsByTagName('button')[0]
  btn && btn.addEventListener('click', () => {
    del(modal)
    response.reload && window.location.reload()
    typeof cb === 'function' && cb()
  })
  return modal
}

import {_request, modal} from "../functions";

class updateVar {
  constructor() {
    if (!this.setVars()) return false
    this.setEvents()
  }

  setVars() {
    this.btns = document.body.querySelectorAll('[data-change]')
    if (!this.btns.length) return false

    this.var_id = document.body.querySelector('[data-id]')
    if (!this.var_id) return false

    this.id = parseInt(this.var_id.dataset.id)
    if (!this.id) return false

    this.events = {}

    this.api_url = this.var_id.dataset.api
    return this.api_url
  }

  setEvents() {
    let input, is_open = false, data = {
      _token: csrf,
      id: this.id
    }, min, max
    each(this.btns, (key, val) => {
      val.addEventListener('click', (e) => {
        e.preventDefault()
        input = document.body.querySelector(`[name="${e.target.dataset.change}"]`)
        if (!input) return modal({status: false, 'msg': 'Error - invalid input'})
        data.type = input.dataset.type || 'update_school'

        if (input.type === 'file') return this.uploadFile(input);

        switch (is_open) {
          case true:
            min = parseInt(input.dataset.min) || 0
            max = parseInt(input.dataset.max) || 50
            if (input.value.length < min) return modal({status: false, msg: `Minimalna ilość znaków: ${min}`})
            if (input.value.length > max) return modal({status: false, msg: `Maksymalna ilość znaków: ${max}`})
            e.target.textContent = e.target.dataset.edit || 'Edytuj'
            input.classList.add('disabled')
            val.blur()
            data[`${input.name}`] = input.value
            _request(this.api_url, JSON.stringify(data), {
              cb: (xhr) => xhr.json().then(response => response.msg && modal(response))
            })
            break
          case false:
            e.target.textContent = e.target.dataset.save || 'Zapisz'
            input.classList.remove('disabled')
            break
        }

        is_open = !is_open
      })
    })
  }

  uploadFile(input) {
    if (this.events[`${input.name}`]) return input.click()
    this.events[`${input.name}`] = (input) => {
      const formData = new FormData()
      each(input.parentNode, (key, val) => {
        val.name && val.value && formData.append(val.name, val.type === 'file' ? val.files[0] : val.value)
      })
      let loader = render(document.body, `<div class="abs top0 z400 left0 row h100 flex align-center justify-center" style="background-color:rgba(0,0,0,.75)"><img class="auto" src="img/loader.png"></div>`)
      _request(input.parentNode.dataset.api_url, formData, {
        type: 'multipart',
        cb: (xhr) => xhr.json().then((response) => {
          del(loader)
          response.msg && modal(response)
        })
      })
    }
    input.addEventListener('change', (e) => {
      e.preventDefault()
      this.events[`${input.name}`](input)
    })

    input.click()
  }
}

export default updateVar

class showInputPassword {
  constructor() {
    if (!this.setVars()) return
    this.setEvents()
  }

  setVars() {
    this.els = document.body.querySelectorAll('[data-show_password]')
    return this.els.length
  }

  setEvents() {
    let input
    each(this.els, (key, val) => {
      val.addEventListener('click', (e) => {
        e.preventDefault()
        input = e.target.previousElementSibling
        switch(input.type) {
          case 'password':
            input.type = 'text'
            break
          case 'text':
            input.type = 'password'
            break
        }
      })
    })
  }

}

export default showInputPassword
